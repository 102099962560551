import React from 'react'
import styled from '@emotion/styled'
// import { Link } from 'gatsby'
import {
  breakpoints,
  container,
  // colors
} from '../../../styles/theme'
import HeroImage from './hero-image'
import Header from './header'

const Hero = () => (
  <HeroWrapper>
    <Container>
      <Header />
      {/* <Callout>
        Gala Dinner & Bond Fashion Show
        <Link
          to="/events/gala-dinner-bond-fashion-show-2021"
          style={{ marginLeft: 16 }}
        >
          Info & Tickets
        </Link>
      </Callout> */}
    </Container>
    <HeroImage />
  </HeroWrapper>
)

export default Hero

const HeroWrapper = styled.div`
  position: relative;
  height: 340px;

  @media (min-width: ${breakpoints.large}px) {
    height: 500px;
  }
`

const Container = styled.div`
  ${container};
  position: absolute;
  z-index: 10;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 16px;
  @media (min-width: ${breakpoints.medium}px) {
    bottom: 120px;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

// const Callout = styled.div`
//   max-width: 640px;
//   text-align: center;

//   margin: 32px auto 0;
//   border: 1px solid ${colors.accent};
//   border-radius: 6px;
//   background: ${colors.black};
//   color: ${colors.white};
//   font-size: 1.7rem;
//   font-weight: 500;
//   text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
//   padding: 6px 16px;
// `
