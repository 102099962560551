import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { breakpoints, colors, fonts } from '../../styles/theme'
import { Link } from 'gatsby'

const FeatureItem = ({ to, title, image }) => (
  <Wrapper>
    <Container>
      <Link to={to}>
        <Header>
          {title && <Title>{title}</Title>}
          <Microcopy>Learn more</Microcopy>
        </Header>
      </Link>
      <HeroImage {...image} alt={title} />
    </Container>
  </Wrapper>
)

const Wrapper = styled.div`
  padding: 8px;
`

const Container = styled.div`
  display: block;
  position: relative;
`

const Header = styled.header`
  position: absolute;
  right: -2px;
  top: 40px;
  z-index: 10;
  border: 1px solid ${colors.accent};
  border-right: 0;
  background: linear-gradient(to left, #333, ${colors.primary}e6 3px);
  color: ${colors.white};
  padding: 4px 16px;
  box-shadow: 0 1px 2px ${colors.primary}44;
  text-align: right;

  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
`

const Title = styled.h2`
  margin: 0;
  margin-bottom: -4px;
  font-family: ${fonts.heading};
  font-weight: 400;
  font-size: 2.6rem;
`

const Microcopy = styled.div`
  font-size: 1.5rem;
  color: ${colors.accent};
  font-weight: 500;
`

const HeroImage = styled(Image)`
  height: 280px;

  @media (min-width: ${breakpoints.medium}px) {
    height: 300px;
  }

  @media (min-width: ${breakpoints.large}px) {
    height: auto;
    max-height: 340px;
  }
`

export default FeatureItem
