import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { colors } from '../../../styles/theme'

const HeroHeader = () => (
  <Header>
    <Heading className="heading--h1">The complete tailoring service</Heading>
    <h1
      css={css`
        font-size: 1.8rem;
        text-align: center;
        font-weight: 500;
        margin: 16px 0 0;
      `}
    >
      Made to measure tailoring in Bournemouth &amp; Essex
    </h1>
  </Header>
)

const Header = styled.header`
  display: inline-block;
  color: ${colors.white};
  background: ${colors.primary}e6;
  padding: 16px 18px;
`

const Heading = styled.p`
  margin: 0;
  font-size: 3.4rem;
  background: ${colors.primary};
  padding: 12px 24px;
  border: 1px solid ${colors.accent};
  text-align: center;
`

export default HeroHeader
