import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Banner, CallToAction } from '../banner-cta'

const CommandAnyOccasion = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "img/purple-cuff-detail-car.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Banner
      image={{
        ...hero.childImageSharp,
        alt: "Men's purple tailored suit showing buttons and cuff detail in car",
        style: {
          minHeight: '360px',
        },
        imgStyle: {
          filter: 'brightness(60%)',
          objectFit: 'cover',
          objectPosition: 'bottom',
          fontFamily: 'object-fit: cover; object-position: bottom',
        },
      }}
    >
      <CallToAction
        heading="Confidence in Your Appearance"
        to="/sports-jackets-blazers/"
        buttonText="Tailored Jackets &amp; Blazers"
      />
    </Banner>
  )
}

export default CommandAnyOccasion
