import React from 'react'
import styled from '@emotion/styled'
import { breakpoints, colors, container } from '../../styles/theme'

import { useStaticQuery, graphql } from 'gatsby'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Item from './feature-item'

const sliderSettings = {
  arrows: false,
  slidesToShow: 2,
  infinite: true,
  swipeToSlide: true,
  autoplay: true,
  autoplaySpeed: 7000,
  dots: true,

  responsive: [
    {
      breakpoint: breakpoints.medium,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const FeaturePanel = () => {
  const {
    // suitTailoring,
    // galaDinner,
    // miamiViceEvent,
    weddingSuits,
    // leisure,
    jacketsBlazers,
    // waistcoats,
    businessSuits,
    tailoredShirts,
    // shoes,
    // eveningSuits,
    // trousers,
  } = useStaticQuery(
    graphql`
      fragment FeatureImageFragment on File {
        childImageSharp {
          fluid(maxHeight: 320, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      query {
        # suitTailoring: file(
        #   relativePath: { eq: "img/suit-tailoring-feature.jpg" }
        # ) {
        #   ...FeatureImageFragment
        # }
        weddingSuits: file(
          relativePath: { eq: "img/wedding-suits-feature.jpg" }
        ) {
          ...FeatureImageFragment
        }
        # shoes: file(relativePath: { eq: "img/shoes-feature.jpg" }) {
        #   ...FeatureImageFragment
        # }
        # leisure: file(relativePath: { eq: "img/leisure-feature.jpg" }) {
        #   ...FeatureImageFragment
        # }
        jacketsBlazers: file(
          relativePath: { eq: "img/sports-jackets-blazers-feature.jpg" }
        ) {
          ...FeatureImageFragment
        }
        # waistcoats: file(relativePath: { eq: "img/waistcoats-feature.jpg" }) {
        #   ...FeatureImageFragment
        # }
        businessSuits: file(
          relativePath: { eq: "img/business-suits-feature.jpg" }
        ) {
          ...FeatureImageFragment
        }
        tailoredShirts: file(
          relativePath: { eq: "img/tailored-shirts-feature.jpg" }
        ) {
          ...FeatureImageFragment
        }
        # eveningSuits: file(
        #   relativePath: { eq: "img/evening-suits-feature.jpg" }
        # ) {
        #   ...FeatureImageFragment
        # }
        # trousers: file(relativePath: { eq: "img/trousers-feature.jpg" }) {
        #   ...FeatureImageFragment
        # }
      }
    `
  )
  return (
    <Container>
      <Panel>
        <Slider {...sliderSettings}>
          <Item
            to="/business-suits/"
            title="Business Suits"
            image={businessSuits.childImageSharp}
          />
          <Item
            to="/wedding-suits/"
            title="Wedding Suits"
            image={weddingSuits.childImageSharp}
          />
          {/* <Item
            to="/custom-shoes/"
            title="Custom Shoes"
            image={shoes.childImageSharp}
          /> */}
          <Item
            to="/tailored-shirts/"
            title="Tailored Shirts"
            image={tailoredShirts.childImageSharp}
          />
          <Item
            to="/sports-jackets-blazers/"
            title="Jackets &amp; Blazers"
            image={jacketsBlazers.childImageSharp}
          />
          {/* <Item
              to="/leisure"
              title="Leisure"
              image={data.leisure.childImageSharp}
            /> */}
          {/* <Item
              to="/waistcoats"
              title="Waistcoats"
              image={data.waistcoats.childImageSharp}
            />
            <Item
              to="/trousers"
              title="Trousers"
              image={data.trousers.childImageSharp}
            /> */}
          {/* <Item
              to="/evening-suits"
              title="Evening Suits"
              image={data.eveningSuits.childImageSharp}
            /> */}
        </Slider>
      </Panel>
    </Container>
  )
}

export default FeaturePanel

const Container = styled.div`
  ${container};
  padding: 0;
`

const Panel = styled.section`
  background: ${colors.white};
  background: linear-gradient(
    to bottom,
    ${colors.white} 240px,
    transparent 240px
  );
  position: relative;
  padding: 8px;
  @media (min-width: ${breakpoints.large}px) {
    top: -80px;
    margin-bottom: -80px;
  }
`
