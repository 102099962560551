import React from 'react'
import { container, colors } from '../styles/theme'
import styled from '@emotion/styled'
import FixedAspectBox from './fixed-aspect-box'

const Interview = () => {
  return (
    <Wrapper>
      <Container>
        <h3 className="heading heading--h2">
          About Fox Tailoring
          <br />
          <span>An interview with John Parrett</span>
        </h3>
        <FixedAspectBox relativeWidth={16} relativeHeight={9}>
          <iframe
            title="An Interview with John Parrett – Founder of Fox Tailoring"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/hNc9I08py1Q?rel=0"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </FixedAspectBox>
      </Container>
    </Wrapper>
  )
}

const Container = styled.div`
  ${container}

  .heading {
    margin-bottom: 32px;
    > span {
      font-size: 0.8em;

      &::before,
      &::after {
        content: '–';
        margin: 0 0.5em;
      }
    }
  }
`

const Wrapper = styled.div`
  background: linear-gradient(to bottom, ${colors.primary}, ${colors.darkGrey});
  border-top: 1px solid ${colors.black};
  border-bottom: 1px solid ${colors.black};
  padding-top: 40px;
  padding-bottom: 64px;
  color: ${colors.white};
  text-align: center;
`

export default Interview
