import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const HeroImage = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "img/home-hero.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 500, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Image
      {...hero.childImageSharp}
      alt="Tailored Prince of Wales grey check suit lapel detail"
      style={{ height: '100%' }}
      imgStyle={{ fontFamily: 'object-fit: cover' }}
      loading="eager"
    />
  )
}

export default HeroImage
