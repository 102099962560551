import React from 'react'
import Layout from '../components/layout'

import Seo from '../components/SEO'
import Hero from '../components/home/hero'
import FeaturePanel from '../components/feature-panel/feature-panel'
import Intro from '../components/home/intro'
import Interview from '../components/interview-video'
import CommandAnyOccasion from '../components/banners/command-any-occasion'

const Homepage = () => (
  <Layout>
    <Seo
      title="Tailors in Bournemouth &amp; Essex Offering Made to Measure Suits, Jackets and Trousers for Business, Weddings and Leisure"
      description="Providing a complete tailoring service across a wide service area. Visit our showrooms in Bournemouth and Essex."
    />
    <Hero />
    <Intro>
      <FeaturePanel />
    </Intro>
    <Interview />
    <CommandAnyOccasion />
  </Layout>
)

export default Homepage
