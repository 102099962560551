import styled from '@emotion/styled'
import React from 'react'
import { breakpoints, colors, container } from '../../styles/theme'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

import decorationsSrc from '../../img/decorations-on-light.svg'

const Intro = ({ children }) => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "img/great-first-impression-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 340, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      {children}
      <Container>
        <HeroImage
          {...hero.childImageSharp}
          alt="Man wearing lilac tailored suit"
        />

        <ContentContainer>
          <Heading className="heading heading--h1">
            You never get a second chance to
            <br />
            <strong>make a great first impression.</strong>
          </Heading>
          <p>
            The Fox Tailoring concept is to provide a personal tailoring service
            wherever you are. Our tailors visit clients in their office, home or
            another convenient location for tailoring consultations.
          </p>
          <p>
            Fox Tailoring covers a wide service area and has showrooms in
            Bournemouth and Romford as well as new facilities in Hants and
            Sussex coming soon.
          </p>
          <p>
            Whether you are in London, Devon or Dorset, we can travel the
            Southern counties as far north as Hertfordshire, Northants,
            Buckinghamshire and Bedfordshire.
          </p>
        </ContentContainer>
      </Container>
    </Wrapper>
  )
}

const HeroImage = styled(Image)`
  box-shadow: 0 8px 32px ${colors.primary}30;
  flex: 0 1 340px;
  max-width: 40%;
  min-width: 300px;
  @media (min-width: ${breakpoints.medium}px) {
    flex-shrink: 1;
    max-width: none;
  }

  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${breakpoints.large}px) {
    margin-left: 0;
    margin-right: 40px;
  }
`

const Wrapper = styled.main`
  position: relative;
  background: ${colors.white};
  padding-top: 1px; // collapse margins

  @media (min-width: ${breakpoints.medium}px) {
    background-image: url(${decorationsSrc});
    background-position: 0 220px;
    background-size: 150% auto;
    background-repeat: no-repeat;
  }

  @media (min-width: ${breakpoints.large}px) {
    background-image: linear-gradient(
        to bottom,
        ${colors.lightGrey}FF 0,
        ${colors.lightGrey}00 80px // safari fix
      ),
      url(${decorationsSrc});
    background-position: 0 0, 50px 180px;
    background-size: 100% auto, cover;
  }
`

const Container = styled.section`
  ${container};
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  padding-top: 60px;
  padding-bottom: 60px;

  @media (min-width: ${breakpoints.large}px) {
    flex-wrap: nowrap;
  }
  @media (min-width: ${breakpoints.medium}px) {
    padding-top: 140px;
    padding-bottom: 140px;
  }
`

const ContentContainer = styled.div`
  flex: 0 1 560px;
  margin-left: auto;
  margin-right: auto;
`

const Heading = styled.h1`
  font-size: 2.6rem;
  margin-top: 40px;
  @media (min-width: ${breakpoints.medium}px) {
    font-size: 4rem;
  }
  @media (min-width: ${breakpoints.large}px) {
    margin-top: 0;
    font-size: 4.4rem;
  }
`

export default Intro
